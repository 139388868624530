var m = require('moment-timezone')

export const isBlitzActive = (blitz) => {
	let now = m.utc()
	let start = m.utc(blitz.startDate).subtract(30, 'm')
	let end = m.utc(blitz.endDate)
	return now.isBetween(start, end)
}
export const isBlitzStarted = (blitz) => {
	let now = m.utc()
	let start = m.utc(blitz.startDate)
	let end = m.utc(blitz.endDate)
	return now.isBetween(start, end)
}
export const getCounts = (start, end) => {
	let now = m.utc()
	return { start: m.utc(start).diff(now, 'seconds'), end: m.utc(end).diff(now, 'seconds') }
}
