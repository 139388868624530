import React, { useState, useEffect } from 'react'
import { Container, List, Icon, Input, Table, Label } from 'semantic-ui-react'
import _ from 'lodash'
import openSocket from 'socket.io-client'
import { SOCKET_BASE } from '../api'
import { connect } from 'react-redux'
import { updateRoom, updateMsg } from '../actions/blitz'
import 'react-virtualized/styles.css' // only needs to be imported once
import { navigate } from '@reach/router'
import CountdownTimer from 'react-component-countdown-timer'
import 'react-component-countdown-timer/lib/styles.css'
import { isBlitzStarted, getCounts } from '../helper'
const moment = require('moment-timezone')

const Comment = (props) => {
	const { time, message, user, index } = props
	return (
		<p
			style={{
				textAlign: 'left',
				padding: '0px 15px',
				backgroundColor: index % 2 === 0 ? 'rgba(0,0,50,.04)' : '#fff',
			}}
		>
			<b>
				{moment(time).format('h:mm')} {user.name} {user.admin && '(admin)'} wrote:
			</b>{' '}
			{message}
		</p>
	)
}

const UserDashboard = (props) => {
	const { logo, logoSmall, bg } = props
	// Global State
	const { blitz, comments, users } = props
	let room = blitz.room
	// Calls and Sales-(Appointments)
	const [totalCalls, setTotalCalls] = useState(0)
	const [totalSales, setTotalSales] = useState(0)
	// Socket
	const [socket, connectSocket] = useState(false)
	// Boolean for timers and countdown display
	const [gameStarted, setGameStarted] = useState(false)
	// Window Responsive Value - In Future Just Use CSS/SASS
	const [winWidth, setWinWidth] = useState(window.innerWidth)

	let startTimer, endTimer, counts, me
	const winWidthFunc = () => {
		setWinWidth(window.innerWidth)
	}

	if (blitz && blitz.active && blitz.active.length === 0) {
		navigate('/')
	} else {
		counts = getCounts(blitz.active[0].startDate, blitz.active[0].endDate)
		startTimer = {
			count: counts.start,
			border: true,
			showTitle: false,
			noPoints: true,
			backgroundColor: 'transparent',
		}
		endTimer = {
			count: counts.end,
			border: true,
			showTitle: false,
			noPoints: true,
			backgroundColor: 'transparent',
		}
		me = users.find((user) => user.email == room.conn.email)
	}

	// Is game active? If not Set Timer to change state
	useEffect(() => {
		if (blitz.active.length === 0) {
			navigate('/')
		} else if (isBlitzStarted(blitz.active[0])) {
			setGameStarted(true)
		} else {
			setGameStarted(false)
			setTimeout(() => {
				setGameStarted(true)
			}, moment.utc(blitz.active[0].startDate).diff(moment.utc(), 'milliseconds'))
		}
	}, [blitz])

	useEffect(() => {
		// Socket cleanup
		if (room.conn) {
			connectSocket(openSocket(SOCKET_BASE, { query: `email=${room.conn.email}` }))
			return function cleanup() {
				if (socket) {
					socket.disconnect()
				}
			}
		}
	}, [])

	useEffect(() => {
		window.addEventListener('resize', winWidthFunc)
		return () =>  window.removeEventListener('resize', winWidthFunc)
	}, [])

	useEffect(() => {

		if (socket !== false) {
			socket.on('update' + room.conn.blitzId, (msg) => {
				updateRoom(msg)
				getTotals(msg.users)
			})
			socket.on('newMsg' + room.conn.blitzId, (msg) => {
				updateMsg(msg)
			})
		}
	}, [socket])

	if (!room.conn || room.conn == undefined) {
		navigate('/')
	}
	const emitAction = async (type, msg) => {
		console.log('emitting')
		socket.emit(type, msg)
	}

	// Responsive Table Sizing
	const getWidth = () => {
		const width =
			!window.innerWidth < 1280
				? {
						width: 'calc(50% - 7px)',
				  }
				: {
						width: '100%',
				  }
		return width
	}

	// Add Total Calls and Sales-(Appointments)
	const getTotals = (roomUsers) => {
		console.log('getTotals')
		let tCalls = 0
		let tSales = 0

		roomUsers.forEach((person, index) => {
			tCalls += person.calls
			tSales += person.sales
		})

		setTotalCalls(tCalls)
		setTotalSales(tSales)
	}

	return (
		<div>
			{socket == false ? (
				<div> Loading socket connection...</div>
			) : (
				<div>
					<div id="dashboard-container" className="bg" style={{ backgroundImage: `url(${bg})` }}></div>
					<div id="register-overlay" className="bg"></div>
					<Container id="user-dashboard" className="center" style={{ zIndex: '3' }}>
						<div id="user-header" className="ui raised card" style={{ width: '100%', height: '120px' }}>
							<div style={{ width: '50%', position: 'absolute', left: '0%' }}>
								<img src={winWidth < 768 ? logoSmall : logo} width="75%" style={{ marginTop: '15px' }} />
							</div>
							<div
								id="user-header-content"
								style={{
									width: '50%',
									position: 'absolute',
									left: '50%',
									top: '50%',
									transform: 'translateY(-50%)',
								}}
							>
								<h1 style={{ fontSize: "1.8em" }}>Welcome to the Group Prospecting Blitz!</h1>
								<div className="user-dashboard-countdown" style={gameStarted ? { display: 'block' } : { display: 'none' }}>
									<h3>Ending in: </h3>
									<CountdownTimer id="end-countdown" {...endTimer} />
								</div>
								<div className="user-dashboard-countdown" style={gameStarted ? { display: 'none' } : { display: 'block' }}>
									<h3>Starting in: </h3>
									<CountdownTimer id="start-countdown" {...startTimer} />
								</div>
							</div>
						</div>

						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div className="ui raised card" style={getWidth()}>
								<h2>{users.length} Current Participants</h2>
								<div style={{ maxHeight: '400px', overflow: 'hidden auto' }}>
									<Table striped>
										<Table.Header>
											<Table.Row>
												<Table.HeaderCell>Name</Table.HeaderCell>
												<Table.HeaderCell>Calls</Table.HeaderCell>
												<Table.HeaderCell>{blitz?.active[0]?.blitzType}</Table.HeaderCell>
											</Table.Row>
										</Table.Header>
										<Table.Body>
											{_.map(users, (user, index) => (
												<Table.Row key={user.email}>
													<Table.Cell>{user.name}</Table.Cell>
													<Table.Cell>{user.calls}</Table.Cell>
													<Table.Cell>{user.sales}</Table.Cell>
												</Table.Row>
											))}
										</Table.Body>
									</Table>
								</div>
								<h2
									style={{
										width: '100%',
										textAlign: 'left',
										display: 'flex',
										justifyContent: 'space-around',
										margin: '15px 0px',
									}}
								>
									<span>{totalCalls} Calls</span>
									<span>{totalSales} {blitz?.active[0]?.blitzType}</span>
								</h2>
							</div>
							<div
								className="ui raised card"
								style={{
									...getWidth(),
									marginTop: '0px',
									marginBottom: '14px',
									height: '520px',
								}}
							>
								<h2>Moderator Comments</h2>
								{blitz.room.messages.length > 0 ? (
									<div style={{ overflow: 'hidden auto' }}>
										{blitz.room.messages.map((commentData, index) => {
											return Comment({ ...commentData, index })
										})}
									</div>
								) : (
									<div>
										<h3 style={{ opacity: 0.5 }}>No Comments</h3>
									</div>
								)}
							</div>
						</div>

						{gameStarted && (
							<div
								id="user-counters"
								className="raised card"
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									width: '100%',
									backgroundColor: 'white',
									borderRadius: '5px',
								}}
							>
								<div style={{ width: 'calc(50% - 7px)', paddingBottom: '10px' }}>
									<h2>Calls Made</h2>
									<div className="icon-wrap">
										<Icon
											name="minus circle"
											size="big"
											onClick={() => {
												emitAction('decCall')
											}}
										></Icon>
										<Input type="number" disabled value={me.calls}></Input>
										<Icon
											name="plus circle"
											size="big"
											onClick={() => {
												emitAction('addCall')
											}}
										></Icon>
									</div>
								</div>
								<div
									style={{
										width: 'calc(50% - 7px)',
										marginTop: '0px',
										marginBottom: '14px',
										paddingBottom: '10px',
									}}
								>
									<h2>{blitz?.active[0]?.blitzType} Set</h2>
									<div className="icon-wrap">
										<Icon
											name="minus circle"
											size="big"
											onClick={() => {
												emitAction('decSale')
											}}
										></Icon>
										<Input type="number" disabled value={me.sales}></Input>
										<Icon
											name="plus circle"
											size="big"
											onClick={() => {
												emitAction('addSale', {})
											}}
										></Icon>
									</div>
								</div>
							</div>
						)}
					</Container>
				</div>
			)}
		</div>
	)
}

function mapStateToProps(state) {
	return {
		blitz: state.blitz,
		comments: _.get(state, 'blitz.active.0.messages', []),
		users: state.blitz.room.users,
	}
}

export default connect(mapStateToProps)(UserDashboard)
