import _ from 'lodash'

const initialState = { all: [], active: [], room: {}, isLoaded: false, type: 'Appointments' }

export default function blitz(state = initialState, action) {
	switch (action.type) {
		case '@blitz/IS_LOADED':
			return { ...state, isLoaded: true }
		case '@blitz/GET_ACTIVE':
			return { ...state, active: action.payload.data }
		case '@blitz/GET_ALL':
			return { ...state, all: action.payload.data }
		case '@blitz/JOIN_ROOM':
			return { ...state, room: { ...action.payload.room, conn: action.payload.conn } }
		case '@blitz/UPDATE_ROOM':
			return { ...state, room: { conn: state.room.conn, ...action.payload } }
		case '@blitz/UPDATE_MESSAGE':
			action.payload.users = _.sortBy(action.payload.users, 'calls')
			return { ...state, room: { ...state.room, messages: action.payload } }
		case '@blitz/UPDATE_ALL_BLITZ':
			return { ...state, all: [...state.all, action.payload] }
		case '@blitz/DELETE_ALL_BLITZ':
			_.remove(state.all, (item) => {
				return item.id === action.payload // Remove on id match
			})
			return { ...state, all: [...state.all] }
		default:
			return state
	}
}
