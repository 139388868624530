import { combineReducers } from 'redux'
import blitz from './blitz'
import user from './user'
import error from './error'
const rootReducer = combineReducers({
	blitz,
	user,
	error,
})

export default rootReducer
