import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Loading from '../containers/Loading'
import Login from '../containers/Login'
import UserDashboard from '../containers/UserDashboard'
import AdminDashboard from '../containers/AdminDashboard'
import AdminLogin from '../containers/AdminLogin'
import Unsubscribe from '../containers/Unsubscribe'
import { Router, navigate } from '@reach/router'
import { fetchActive, fetchAll } from '../actions/blitz'
import { Message } from 'semantic-ui-react'
import _ from 'lodash'
import 'normalize.css'

const logo = require('../assets/logo2.png')
const logoSmall = require('../assets/logo2-small.png')
const bg = require('../assets/login-bg.jpg')
let blitzLoaded = false

function Routes() {
	const dispatch = useDispatch()
	const blitz = useSelector((state) => state.blitz)
	const error = useSelector((state) => state.error)
	const errorMessage = _.get(error, 'message', null)
	// Get Blitz State
	useEffect(() => {
		fetchAll().then((res) => {
			fetchActive().then(() => {
				// isLoaded set in fetchActive
			})
		})
	}, [])

	useEffect(() => {
		if (blitz.isLoaded && !blitzLoaded) {
			console.log({ blitzLoaded })
			blitzLoaded = true
			navigate('/login')
		}
	}, [blitz])

	return (
		<div style={{ textAlign: 'center' }}>
			<Router>
				<Loading path="/" logo={logo} />
				<Login path="/login" logo={logo} bg={bg} />
				<UserDashboard path="/user-dashboard" logo={logo} logoSmall={logoSmall} bg={bg} />
				<AdminLogin path="/admin-login" logo={logo} bg={bg} />
				<AdminDashboard path="/admin-dashboard" logo={logoSmall} bg={bg} />
				<Unsubscribe path="/unsubscribe" logo={logo} bg={bg} />
				<div path="/loading" />
			</Router>
			{errorMessage && errorMessage.length > 0 && (
				<Message id="errorMessage" icon="cancel" header="There was an error" content={errorMessage} />
			)}
		</div>
	)
}

export default Routes
