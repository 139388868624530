import store from '../store'
import * as clientFetch from '../api'

export const loginAdmin = async (params) => {
	try {
		const res = await clientFetch.makePostRequest('/login', params)
		console.log('login res', res)
		store.dispatch({
			type: '@auth/LOG_IN',
			payload: res.data,
		})
		return res
	} catch (err) {
		console.error(err)
		console.log(`Error logging in: ${err}`)
	}
}
