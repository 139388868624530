import React, { useState, useEffect } from 'react'
import { navigate } from '@reach/router'
import { Card, Button } from 'semantic-ui-react'

const Unsubscribe = (props) => {
	const { logo, bg } = props
	const logoStyles = {
		padding: '30px 30px 0px 30px',
		width: '80%',
		margin: '0 auto',
		height: '100px',
		backgroundImage: `url(${logo})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
		backgroundPosition: 'center',
	}

	return (
		<div>
			<div id="dashboard-container" className="bg" style={{ backgroundImage: `url(${bg})` }}></div>
			<div id="register-overlay" className="bg"></div>
			<div className="center" style={{ zIndex: '3', transform: 'translateX(-50%) translateY(-50%) scale(1.25)' }}>
				<div className="ui raised card">
					<div style={logoStyles}></div>
					<h2>
						You have been unsubscribed.
						<br />
						<br />
						Register to
						<br />
						resubscribe.
					</h2>
					<Button
						onClick={() => {
							navigate('/')
						}}
					>
						Go to Homepage
					</Button>
				</div>
			</div>
		</div>
	)
}

export default Unsubscribe
