import axios from 'axios'
import store from '../store/index'

const setError = (err) => {
	console.log('setError')
	console.log({ err })
	store.dispatch({ type: '@error/SET_MESSAGE', payload: `${err.message}. ${err.response.data.error}` })
	setTimeout(function() {
		store.dispatch({ type: '@error/SET_MESSAGE', payload: '' })
	}, 6000)
}

export const BASE = 'https://solutionsblitz.com/api'
export const SOCKET_BASE = 'https://solutionsblitz.com'
// export const BASE = 'http://localhost:8081/api'
// export const SOCKET_BASE = 'localhost:8081'
export const makePostRequest = async (path, obj, token = false) => {
	console.log({ token })
	try {
		if (token) {
			const response = await axios.post(BASE + path, obj, {
				headers: {
					'x-access-token': token,
				},
			})
			return response
		} else {
			const response = await axios.post(BASE + path, obj)
			return response
		}
	} catch (error) {
		console.log('catch error')
		setError(error)
	}
}
export const put = async (path, obj, token = false) => {
	try {
		if (token) {
			const response = await axios.put(BASE + path, obj, {
				headers: {
					'x-access-token': token,
				},
			})
			return response
		} else {
			const response = await axios.put(BASE + path, obj)
			return response
		}
	} catch (error) {
		console.log('catch error')
		setError(error)
	}
}

export const get = async (path) => {
	try {
		const response = await axios.get(BASE + path)
		console.log({ response })
		return response
	} catch (error) {
		console.log('catch error')
		setError(error)
	}
}

export const adminGet = async (path, token) => {
	try {
		const response = await axios.get(BASE + path, {
			headers: {
				'x-access-token': token,
			},
		})
		console.log({ response })
		return response
	} catch (error) {
		console.log('catch error')
		setError(error)
	}
}
