const initialState = false

export default function user(state = initialState, action) {
	switch (action.type) {
		case '@error/SET_MESSAGE':
			console.log(action.payload)
			return { ...state, message: action.payload }
		default:
			return state
	}
}
