import React, { useState, useEffect } from 'react'
import { navigate } from '@reach/router'
import { Card, Icon, Input, Button, Transition, Loader, Form } from 'semantic-ui-react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { makePostRequest } from '../api'
import { fetchActive, fetchAll, joinRoom } from '../actions/blitz'
import { loginAdmin } from '../actions/users'

const AdminDashboard = (props) => {
	const { logo, bg } = props
	// Global State Props
	const { activeBlitzId, activeBlitzName, allBlitz, name } = props
	// Global Dispatch Props
	const { fetchActive, fetchAll } = props

	const [loggingIn, setLoggingIn] = useState(false)
	const [email, setEmail] = useState()
	const [password, setPassword] = useState()

	const logoStyles = {
		padding: '30px 30px 0px 30px',
		width: '80%',
		margin: '0 auto',
		height: '100px',
		backgroundImage: `url(${logo})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
		backgroundPosition: 'center',
	}

	const login = (route) => {
		if (email.includes('@')) {
			setLoggingIn(true)
			loginAdmin({ email, password })
				.then((res) => {
					if (res) {
						navigate('/admin-dashboard')
					} else {
						setLoggingIn(false)
					}
				})
				.catch((err) => {
					console.error(err)
				})
		}
	}

	return (
		<div>
			<div id="dashboard-container" className="bg" style={{ backgroundImage: `url(${bg})` }}></div>
			<div id="register-overlay" className="bg"></div>
			<div className="center" style={{ zIndex: '3', transform: 'translateX(-50%) translateY(-50%) scale(1.25)' }}>
				<div className="ui raised card">
					<div style={logoStyles}></div>
					<label style={{ opacity: '0.5', paddingBottom: '15px' }}>
						<i>Administrator Login</i>
					</label>
					<Form>
						<Form.Field>
							<Input
								type="email"
								placeholder="Email"
								style={{ width: '80%', margin: '0px' }}
								onChange={(e) => {
									setEmail(e.target.value)
								}}
								required
							/>
						</Form.Field>
						<Form.Field>
							<Input
								type="text"
								placeholder="Password"
								type="password"
								style={{ width: '80%', margin: '15px 0px 15px 0px' }}
								onChange={(e) => {
									setPassword(e.target.value)
								}}
								onKeyPress={(e) => {
									if (e.which === 13) {
										login()
									}
								}}
								required
							/>
						</Form.Field>
						<Button
							disabled={!email && !password}
							type="submit"
							onClick={() => {
								login('/admin-dashboard')
							}}
							style={{ width: '230px', marginBottom: '15px' }}
						>
							Login to Admin Dashboard
							{loggingIn && <Loader />}
						</Button>
					</Form>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		allBlitz: _.get(state, 'blitz.all', []),
		activeBlitzId: _.get(state, 'blitz.active.0.id', undefined),
		activeBlitzName: _.get(state, 'blitz.active.0.name', ''),
		auth: state.auth,
	}
}

const mapPropsToDispatch = (dispatch) => {
	return {
		fetchActive: () => fetchActive(),
		fetchAll: () => fetchAll(),
		// joinRoom: (obj) => joinRoom(obj),
	}
}

export default connect(mapStateToProps, mapPropsToDispatch)(AdminDashboard)
