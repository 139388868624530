import store from '../store'
import * as clientFetch from '../api'

export const fetchActive = async () => {
	try {
		const res = await clientFetch.get('/blitz/active')
		store.dispatch({
			type: '@blitz/GET_ACTIVE',
			payload: res,
		})
		store.dispatch({ type: '@blitz/IS_LOADED', payload: true })
	} catch (err) {
		console.error(err.response)
		//alert(`Error getting active blitz: ${err.response}`)
	}
}

export const fetchAll = async () => {
	try {
		const res = await clientFetch.get('/blitz/index')
		store.dispatch({
			type: '@blitz/GET_ALL',
			payload: res,
		})
	} catch (err) {
		console.error(err.response)
		//alert(`Error getting all blitz: ${err.response}`)
	}
}

export const joinRoom = async (payload) => {
	console.log('joinRoom reducer')
	console.log({ payload })
	store.dispatch({
		type: '@blitz/JOIN_ROOM',
		payload,
	})
}

export const updateAllBlitz = async (newBlitz) => {
	store.dispatch({ type: '@blitz/UPDATE_ALL_BLITZ', payload: newBlitz })
}

export const deleteAllBlitz = async (blitzId) => {
	store.dispatch({ type: '@blitz/DELETE_ALL_BLITZ', payload: blitzId })
}

export const updateRoom = async (room) => {
	store.dispatch({ type: '@blitz/UPDATE_ROOM', payload: room })
}
export const updateMsg = async (msg) => {
	store.dispatch({ type: '@blitz/UPDATE_MESSAGE', payload: msg })
}
