import React, { useState, useEffect } from 'react'
import { Container, List, Icon, Input, Button, Table, Popup, TableHeaderCell, Card, Form } from 'semantic-ui-react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { makePostRequest } from '../api'

const ManageAccounts = (props) => {
	const { type, blitz, auth } = props
	let room = blitz.room
	// Local User Data for Sorting
	const [userData, setUserData] = useState([])
	// Table Sorting
	const [column, setColumn] = useState(null)
	const [direction, setDirection] = useState(null)
	// Edit Admin Detail
	const [selectedAdminDetails, setSelectedAdminDetails] = useState(null)
	const [editAdminDetails, setEditAdminDetails] = useState({ name: '', email: '' })
	const [newUser, setNewUser] = useState(false)

	//User form state
	const [email, setEmail] = useState('')
	const [name, setName] = useState('')
	const [password, setPassword] = useState('')
	const handleSort = (clickedColumn) => {
		const newDirection = direction === 'ascending' ? 'descending' : 'ascending'
		if (column !== clickedColumn) {
			setColumn(clickedColumn)
			setUserData(_.sortBy(userData, [clickedColumn]))
			setDirection('ascending')
		} else {
			setUserData(userData.reverse())
			setDirection(newDirection)
		}
	}

	// Get Admins
	useEffect(() => {
		setUserData(room.users)
	}, [])

	// Update Local State when Global Room Changes
	useEffect(() => {
		setUserData(room.users)
	}, [room])

	const blockEmail = (email) => {
		makePostRequest('/ban/email', { email }, auth.token)
			.then((res) => {
				console.log({ res })
			})
			.catch((err) => {
				console.log({ err })
				console.error(err)
			})
	}
	const blockIP = (ip, email) => {
		makePostRequest('/ban/ip', { ip, email }, auth.token)
			.then((res) => {
				console.log({ res })
			})
			.catch((err) => {
				console.log({ err })
				console.error(err)
			})
	}

	const deleteUser = (ip, email) => {
		makePostRequest('/ban/remove', { ip, email }, auth.token)
			.then((res) => {
				console.log({ res })
			})
			.catch((err) => {
				console.log({ err })
				console.error(err)
			})
	}

	return (
		<React.Fragment>
			<h1 style={{ marginBottom: '15px', textAlign: 'left', float: 'left' }}>Current Blitz Users</h1>
			<Input
				type="text"
				placeholder="Search"
				icon="search"
				style={{ float: 'right' }}
				onChange={(e) => {
					if (e.target.value) {
						const searchTerm = e.target.value
						const filtered = room.users.map((user) => {
							if (user.name.includes(searchTerm) || user.email.includes(searchTerm) || user.ip.includes(searchTerm)) {
								return user
							}
						})
						setUserData(filtered)
					} else {
						setUserData(room.users)
					}
				}}
			/>
			{userData ? (
				<div className="admin-table">
					<Table sortable striped>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell
									sorted={column === 'name' ? direction : null}
									onClick={() => {
										handleSort('name')
									}}
								>
									Name
								</Table.HeaderCell>
								<Table.HeaderCell
									sorted={column === 'email' ? direction : null}
									onClick={() => {
										handleSort('email')
									}}
								>
									Email
								</Table.HeaderCell>
								<Table.HeaderCell
									sorted={column === 'ip' ? direction : null}
									onClick={() => {
										handleSort('ip')
									}}
								>
									IP Address
								</Table.HeaderCell>
								<Table.HeaderCell
									sorted={column === 'masterAdmin' ? direction : null}
									onClick={() => {
										handleSort('masterAdmin')
									}}
								>
									Master
								</Table.HeaderCell>
								<Table.HeaderCell
									sorted={column === 'connect' ? direction : null}
									onClick={() => {
										handleSort('connect')
									}}
								>
									Status
								</Table.HeaderCell>
								<TableHeaderCell>
									<Icon name="setting" />
								</TableHeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{userData.map((user, index) => (
								<React.Fragment key={index}>
									{user && (
										<Table.Row positive={user.connect}>
											<Table.Cell>{user.name}</Table.Cell>
											<Table.Cell>{user.email}</Table.Cell>
											<Table.Cell>{user.ip}</Table.Cell>
											<Table.Cell>{user.masterAdmin ? 'Yes' : 'No'}</Table.Cell>
											<Table.Cell>{user.connect ? 'Active' : 'Not Active'}</Table.Cell>
											<Table.Cell>
												<Popup
													id="admin-popup"
													content={
														<ul>
															<li
																onClick={() => {
																	blockEmail(user.email)
																}}
															>
																Block User by Email
															</li>
															<li
																onClick={() => {
																	blockIP(user.ip, user.email)
																}}
															>
																Block User by IP
															</li>
															<li
																onClick={() => {
																	deleteUser(user.ip, user.email)
																}}
															>
																Delete User
															</li>
														</ul>
													}
													on="click"
													pinned
													trigger={<Icon name="setting" />}
												/>
											</Table.Cell>
										</Table.Row>
									)}
								</React.Fragment>
							))}
						</Table.Body>
					</Table>
				</div>
			) : (
				<h3
					style={{
						opacity: '0.5',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translateX(-50%) translateY(-50%)',
					}}
				>
					No Current Users
				</h3>
			)}
		</React.Fragment>
	)
}

const mapStateToProps = (state) => {
	return {
		auth: state.user.auth,
		blitz: state.blitz,
	}
}

export default connect(mapStateToProps)(ManageAccounts)
