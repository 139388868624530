import React, { useState, useEffect } from 'react'
import { Button, Card, Form, Table, Icon, Modal, Header, TableHeaderCell } from 'semantic-ui-react'
import _, { set } from 'lodash'
import { connect } from 'react-redux'
import { adminGet, makePostRequest } from '../api'

const moment = require('moment-timezone')

const BlitzStatistics = (props) => {
	// Global State
	const { type, blitz, allBlitz, activeBlitz } = props
	// Props
	const { auth, token } = props
	// Table Sorting
	const [column, setColumn] = useState(null)
	const [direction, setDirection] = useState(null)
	// Statistics Functionality
	const [currentTableData, setCurrentTableData] = useState([])
	const [allTableData, setAllTableData] = useState([])
	const [selectedTableData, setSelectedTableData] = useState([])
	const [selectedTableUsers, setSelectedTableUsers] = useState([])
	const [selectedTableIndex, setSelectedTableIndex] = useState(null)
	const [statsModal, setStatsModal] = useState(false)

	const handleSort = (clickedColumn, allData = true) => {
		const newDirection = direction === 'ascending' ? 'descending' : 'ascending'
		if (column !== clickedColumn) {
			setColumn(clickedColumn)
			if (allData) {
				setAllTableData(_.sortBy(allTableData, [clickedColumn]))
			} else {
				switch (clickedColumn) {
					case 'name':
						setSelectedTableUsers(
							_.sortBy(selectedTableUsers, function (user) {
								return user[1]
							}),
						)
						break
					case 'email':
						setSelectedTableUsers(
							_.sortBy(selectedTableUsers, function (user) {
								return user[0]
							}),
						)
						break
					case 'location':
						setSelectedTableUsers(
							_.sortBy(selectedTableUsers, function (user) {
								return user[4]
							}),
						)
						break
					case 'phone':
						setSelectedTableUsers(
							_.sortBy(selectedTableUsers, function (user) {
								return user[5]
							}),
						)
						break
					case 'calls':
						setSelectedTableUsers(
							_.sortBy(selectedTableUsers, function (user) {
								return user[2]
							}),
						)
						break
					case 'appointments':
						setSelectedTableUsers(
							_.sortBy(selectedTableUsers, function (user) {
								return user[3]
							}),
						)
						break
					case 'associate':
						setSelectedTableData(
							_.sortBy(selectedTableUsers, (user) => {
								return user[6]
							}),
						)
						break
				}
			}
			setDirection('ascending')
		} else {
			allData ? setAllTableData(allTableData.reverse()) : setSelectedTableUsers(selectedTableUsers.reverse())
			setDirection(newDirection)
		}
	}

	// Get Current Blitz Data
	useEffect(() => {
		if (activeBlitz.length > 0) {
			let totalCalls = 0
			let totalSales = 0

			activeBlitz[0].users.forEach((user) => {
				totalCalls += user.calls
				totalSales += user.sales
			})

			setCurrentTableData([
				{
					id: Math.random(),
					name: activeBlitz[0].name,
					calls: totalCalls,
					sales: totalSales,
					startDate: activeBlitz[0].startDate,
				},
			])
		}
	}, [activeBlitz])

	// Get All Blitz Data
	useEffect(() => {
		adminGet('/statistics/basic', token)
			.then((res) => {
				console.log(res)
				setAllTableData(res.data.reverse())
			})
			.catch((err) => {
				console.log(err)
			})
	}, [allBlitz])

	// Format Data After Select
	const handleSelect = (event, { value }) => {
		// Gets selected Blitz by ID match
		const selectedBlitz = allBlitz.find((blitz) => {
			if (blitz.id === value) {
				return blitz
			}
		})
	}

	const showStatistics = (item, index, currentBlitz) => {
		if (currentBlitz) {
			const currentBlitzUsers = activeBlitz[0].users.map((user, index) => {
				return [user.email, user.name, user.calls, user.sales, user.location]
			})
			const currentBlitzStatistics = [
				{ users: currentBlitzUsers, name: activeBlitz[0].name, startDate: activeBlitz[0].startDate },
			]
			setSelectedTableData(currentBlitzStatistics)
		} else {
			setSelectedTableIndex(index)
			getSpecificBlitzStats(item.id)
		}
	}

	const deleteStatistics = (id) => {
		makePostRequest(`/statistics/delete/${id}`, {}, token)
			.then((res) => {
				// Remove from Local State
				const filtered = allTableData.filter((val, index) => {
					return val.id !== id
				})
				setAllTableData(filtered)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	const getSpecificBlitzStats = (id) => {
		adminGet(`/statistics/id/${id}`, token)
			.then((res) => {
				let data = res.data
				data = {
					...res.data,
					users: JSON.parse(res.data.users).sort((a, b) => b[1] - a[1]),
				}
				console.log({ data })
				setSelectedTableData([data])
				setSelectedTableUsers(data.users)
				setStatsModal(true)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	console.log({ selectedTableData })

	const GeneralTable = (props) => {
		const { tableData, currentBlitz } = props

		return (
			<Table selectable striped sortable>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell
							sorted={column === 'name' ? direction : null}
							onClick={() => {
								handleSort('name', true)
							}}
						>
							Name
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={column === 'startDate' ? direction : null}
							onClick={() => {
								handleSort('startDate', true)
							}}
						>
							Date
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={column === 'calls' ? direction : null}
							onClick={() => {
								handleSort('calls', true)
							}}
						>
							Total Calls
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={column === 'sales' ? direction : null}
							onClick={() => {
								handleSort('sales', true)
							}}
						>
							Total Appts
						</Table.HeaderCell>
						<Table.HeaderCell 
							sorted={column === 'type' ? direction : null}
							onClick={() => {
								handleSort('type', true)
							}
						}>
							Type
						</Table.HeaderCell>
						<Table.HeaderCell>Delete</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{_.map(tableData, (item, index) => {
						return (
							<Table.Row key={index} className={selectedTableIndex === index && !currentBlitz ? 'selected-row' : ''}>
								<Table.Cell
									onClick={() => {
										showStatistics(item, index, currentBlitz)
									}}
								>
									{item.name}
								</Table.Cell>
								<Table.Cell
									onClick={() => {
										showStatistics(item, index, currentBlitz)
									}}
								>
									{moment(item.startDate).format('LLLL')}
								</Table.Cell>
								<Table.Cell
									onClick={() => {
										showStatistics(item, index, currentBlitz)
									}}
								>
									{item.calls === null ? 0 : item.calls}
								</Table.Cell>
								<Table.Cell
									onClick={() => {
										showStatistics(item, index, currentBlitz)
									}}
								>
									{item.sales === null ? 0 : item.sales}
								</Table.Cell>
								<Table.Cell
									onClick={() => {
										showStatistics(item, index, currentBlitz)
									}}
								>
									{item.blitzType}
								</Table.Cell>
								<Table.Cell
									style={{ zIndex: '3' }}
									onClick={(e) => {
										e.preventDefault()
										deleteStatistics(item.id)
									}}
								>
									<Icon name="trash" />
								</Table.Cell>
							</Table.Row>
						)
					})}
				</Table.Body>
			</Table>
		)
	}

	const generateSelectedTable = () => (
		<Table striped sortable>
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell
						sorted={column === 'name' ? direction : null}
						onClick={() => {
							handleSort('name', false)
						}}
					>
						Name
					</Table.HeaderCell>
					<Table.HeaderCell
						sorted={column === 'email' ? direction : null}
						onClick={() => {
							handleSort('email', false)
						}}
					>
						Email
					</Table.HeaderCell>
					<Table.HeaderCell
						sorted={column === 'associate' ? direction : null}
						onClick={() => {
							handleSort('associate', false)
						}}
					>
						Associate #
					</Table.HeaderCell>
					<Table.HeaderCell
						sorted={column === 'location' ? direction : null}
						onClick={() => {
							handleSort('location', false)
						}}
					>
						Location
					</Table.HeaderCell>
					<Table.HeaderCell
						sorted={column === 'phone' ? direction : null}
						onClick={() => {
							handleSort('phone', false)
						}}
					>
						Phone
					</Table.HeaderCell>
					<Table.HeaderCell
						sorted={column === 'calls' ? direction : null}
						onClick={() => {
							handleSort('calls', false)
						}}
					>
						Calls
					</Table.HeaderCell>
					<Table.HeaderCell
						sorted={column === 'appointments' ? direction : null}
						onClick={() => {
							handleSort('appointments', false)
						}}
					>
						{selectedTableData[0]?.blitzType}
					</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			<Table.Body>
				{_.map(selectedTableUsers, (userArr, index) => {
					return (
						<Table.Row key={index}>
							<Table.Cell>{userArr[1]}</Table.Cell>
							<Table.Cell>{userArr[0]}</Table.Cell>
							<Table.Cell>{userArr[6] ? userArr[6] : 'null'}</Table.Cell>
							<Table.Cell>{userArr[4]}</Table.Cell>
							<Table.Cell>{userArr[5]}</Table.Cell>
							<Table.Cell>{userArr[2] === null ? 0 : userArr[2]}</Table.Cell>
							<Table.Cell>{userArr[3] === null ? 0 : userArr[3]}</Table.Cell>
						</Table.Row>
					)
				})}
			</Table.Body>
		</Table>
	)

	const selectedTableModal = (currentTableData) => (
		<Modal id="stats-modal" open={statsModal} style={{ minWidth: 'fit-content', width: '1024px' }}>
			<Modal.Header>{`${selectedTableData[0].name} - ${moment(selectedTableData[0].startDate).format(
				'LLLL',
			)}`}</Modal.Header>
			<Modal.Content>
				<Modal.Description>{generateSelectedTable(selectedTableData)}</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button
					className="submit"
					onClick={() => {
						setStatsModal(false)
						setSelectedTableData([])
						setSelectedTableIndex(null)
					}}
				>
					Close
				</Button>
			</Modal.Actions>
		</Modal>
	)

	return (
		<React.Fragment>
			<h1 style={{ marginBottom: '15px', textAlign: 'left' }}>Current Blitz Statistics</h1>
			{currentTableData.length > 0 ? (
				<GeneralTable tableData={allTableData} currentBlitz={true} />
			) : (
				<h3 style={{ opacity: 0.5 }}>
					<i>Currently there is not an active Blitz session.</i>
				</h3>
			)}
			{allTableData.length > 0 ? (
				<React.Fragment>
					<h1 style={{ marginBottom: '15px', textAlign: 'left', float: 'left' }}>All Blitz Statistics</h1>
					<GeneralTable tableData={allTableData} currentBlitz={false} />
				</React.Fragment>
			) : (
				<h3 style={{ opacity: 0.5 }}>
					<i>Currently there are not any past Blitz sessions.</i>
				</h3>
			)}
			{statsModal && selectedTableModal(selectedTableData)}
		</React.Fragment>
	)
}

const mapStateToProps = (state) => {
	return {
		blitz: state.blitz,
		activeBlitz: state.blitz.active,
		allBlitz: state.blitz.all,
	}
}

export default connect(mapStateToProps)(BlitzStatistics)
