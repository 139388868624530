import React from 'react'
import { Progress } from 'semantic-ui-react'

const Loading = (props) => {
	const { logo } = props

	return (
		<div id="App-modal">
			<div className="fg">
				<img src={logo} alt="LegalShield Business Solutions" style={{ width: '100%', height: 'auto' }} />
				<Progress size="tiny" percent={100} color="green" active />
			</div>
		</div>
	)
}

export default Loading
