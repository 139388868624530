import React, { useState, useEffect } from 'react'
import { Button, Segment, Form, TextArea, Dropdown } from 'semantic-ui-react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { makePostRequest } from '../api'

const moment = require('moment')

const ManageComments = (props) => {
	// Global State
	const { allBlitz, socket, room, auth } = props
	// Comment Functionality
	const [selectedComment, setSelectedComment] = useState(false)
	const [newComment, setNewComment] = useState()
	const emitAction = async (type, msg) => {
		socket.emit(type, msg)
	}

	const deleteComment = () => {
		makePostRequest('/admin/removeMsg', {
			blitzId: allBlitz[0].id,
			index: selectedComment
		}, auth.token).then(res => {
			setSelectedComment(false)
		}).catch((err) => {
			console.log({err})
		})
	}

	return (
		<React.Fragment>
			<h1 style={{ marginBottom: '15px', textAlign: 'left' }}>Moderator Comments</h1>
			{room.messages !== undefined && room.messages.length > 0 ? (
				<Segment.Group id="comment-wrap" style={{ maxHeight: '500px', overflow: 'hidden auto' }}>
					{_.map(room.messages, ({ time, user, message }, index) => (
						<Segment
							key={index}
							style={{ textAlign: 'left', cursor: 'pointer' }}
							className={selectedComment === index ? 'selected-row' : ''}
							onClick={() => {
								console.log({index})
								setSelectedComment(index)
							}}
						>
							<b>
								{moment(time).format('h:mm')} {user.name} {user.admin && '(admin)'} wrote:
							</b>{' '}
							{message}
						</Segment>
					))}
				</Segment.Group>
			) : (
				<h3 style={{ opacity: '0.5', textAlign: 'left' }}>No Comments</h3>
			)}
			<Form style={{ marginBottom: '15px' }}>
				<TextArea
					placeholder="Enter Comment"
					style={{ minHeight: 100 }}
					value={newComment}
					onChange={(e) => {
						setNewComment(e.target.value)
					}}
				/>

				<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
					<Button
						disabled={selectedComment === false}
						onClick={() => {
							deleteComment()
						}}
					>
						Delete Comment
					</Button>
					<Button
						className={`submit ${!newComment ? 'disabled' : ''}`}
						disabled={!newComment}
						type="submit"
						onClick={() => {
							emitAction('adminMessage', newComment)
							setNewComment('')
						}}
					>
						Submit Comment
					</Button>
				</div>
			</Form>
		</React.Fragment>
	)
}

const mapStateToProps = (state) => {
	return {
		allBlitz: state.blitz.all,
		room: state.blitz.room,
		auth: state.user.auth,
	}
}

export default connect(mapStateToProps)(ManageComments)
