import React, { useState, useEffect } from 'react'
import { Container, List, Icon, Input, Button, Table, Popup, TableHeaderCell, Card, Form } from 'semantic-ui-react'
import _ from 'lodash'
import { connect, useStore } from 'react-redux'
import { makePostRequest } from '../api'

const ManageAccounts = (props) => {
	const { type, blitz, auth } = props
	let room = blitz.room
	// Local User Data for Sorting
	const [userData, setUserData] = useState([])
	const [filteredUserData, setFilteredUserData] = useState([])
	// Table Sorting
	const [column, setColumn] = useState(null)
	const [direction, setDirection] = useState(null)
	// Edit Admin Detail
	const [selectedAdminDetails, setSelectedAdminDetails] = useState(null)
	const [editAdminDetails, setEditAdminDetails] = useState({ name: '', email: '' })
	const [newUser, setNewUser] = useState(false)

	//User form state
	const [email, setEmail] = useState('')
	const [name, setName] = useState('')
	const [password, setPassword] = useState('')
	const handleSort = (clickedColumn) => {
		const newDirection = direction === 'ascending' ? 'descending' : 'ascending'
		if (column !== clickedColumn) {
			setColumn(clickedColumn)
			setUserData(_.sortBy(userData, [clickedColumn]))
			setDirection('ascending')
		} else {
			setUserData(userData.reverse())
			setDirection(newDirection)
		}
	}

	// Get Admins
	useEffect(() => {
		getAllUsers()
	}, [])

	const getAllUsers = (addedUser) => {
		makePostRequest('/admin/getAllUsers', {}, auth.token)
			.then((res) => {
				console.log({ res })
				if (userData.length > 0) {
					setUserData(res.data)
					setFilteredUserData(res.data)
				} else {
					setUserData([...res.data, addedUser])
					setFilteredUserData([...res.data, addedUser])
				}
			})
			.catch((err) => {
				console.log({ err })
				console.error(err)
			})
	}

	const blockEmail = (email) => {
		makePostRequest('/ban/email', { email }, auth.token)
			.then((res) => {
				console.log({ res })
			})
			.catch((err) => {
				console.log({ err })
				console.error(err)
			})
	}
	const blockIP = (ip, email) => {
		makePostRequest('/ban/ip', { ip, email }, auth.token)
			.then((res) => {
				console.log({ res })
			})
			.catch((err) => {
				console.log({ err })
				console.error(err)
			})
	}

	const deleteUser = (id) => {
		makePostRequest('/admin/deleteUser', { id }, auth.token)
			.then((res) => {
				console.log({ res })
				getAllUsers();
			})
			.catch((err) => {
				console.log({ err })
				console.error(err)
			})
	}

	const postNewAdmin = () => {
		let body = {
			email,
			password,
			name,
			admin: 1,
		}
		makePostRequest('/register', body, auth.token)
			.then((res) => {
				console.log({ res })
				setSelectedAdminDetails(null)
				setEmail('')
				setName('')
				setPassword('')
				setNewUser(false)

				getAllUsers();
			})
			.catch((err) => {
				console.log({ err })
				console.error(err)
			})
	}

	const submitEditedAdminDetails = (user) => {
		makePostRequest('/admin/updateUser', user, auth.token)
			.then((res) => {
				console.log({ res })
				setSelectedAdminDetails(null)
				getAllUsers(res.data) // Gets new list of users because its serverside - not global state
			})
			.catch((err) => {
				console.log({ err })
				console.error(err)
			})
	}

	return (
		<React.Fragment>
			<h1 style={{ marginBottom: '15px', textAlign: 'left', float: 'left' }}>Current Blitz Admins</h1>
			<Input
				type="text"
				placeholder="Search"
				icon="search"
				style={{ float: 'right' }}
				onChange={(e) => {
					setFilteredUserData([])
					if (e.target.value) {
						const searchTerm = e.target.value
						const filtered = userData.map((user, index) => {
							if (user) {
								console.log(user)
								console.log(searchTerm)
								console.log(user.name.includes(searchTerm))
								if (user.name.includes(searchTerm) || user.email.includes(searchTerm)) {
									return user
								}
							}
						})
						if (filtered.length > 0) {
							setFilteredUserData(filtered)
						}
					} else {
						const copyUserData = JSON.parse(JSON.stringify(userData))
						setFilteredUserData(userData)
					}
				}}
			/>
			{userData ? (
				<>
					<div className="admin-table">
						<Table sortable striped>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell
										sorted={column === 'name' ? direction : null}
										onClick={() => {
											handleSort('name')
										}}
									>
										Name
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === 'email' ? direction : null}
										onClick={() => {
											handleSort('email')
										}}
									>
										Email
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === 'masterAdmin' ? direction : null}
										onClick={() => {
											handleSort('masterAdmin')
										}}
									>
										Master
									</Table.HeaderCell>
									<TableHeaderCell>
										<Icon name="setting" />
									</TableHeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{filteredUserData.map((user, index) => {
									return (
										<React.Fragment key={index}>
											{user && (
												<Table.Row>
													<Table.Cell>{user.name}</Table.Cell>
													<Table.Cell>{user.email}</Table.Cell>
													<Table.Cell>{user.masterAdmin ? 'Yes' : 'No'}</Table.Cell>
													<Table.Cell>
														<Popup
															id="admin-popup"
															content={
																<ul>
																	<li
																		onClick={() => {
																			setSelectedAdminDetails({
																				name: user.name,
																				email: user.email,
																				id: user.id,
																				newPassword: '',
																			})
																		}}
																	>
																		Edit Admin Details
																	</li>
																	{auth.user.masterAdmin && (
																		<React.Fragment>
																			<li
																				onClick={() => {
																					submitEditedAdminDetails({ ...user, opUser: 1 })
																				}}
																			>
																				Make Master Admin
																			</li>
																			<li
																				onClick={() => {
																					deleteUser(user.id)
																				}}
																			>
																				Delete Admin
																			</li>
																		</React.Fragment>
																	)}
																</ul>
															}
															on="click"
															pinned
															trigger={<Icon name="setting" />}
														/>
													</Table.Cell>
												</Table.Row>
											)}
										</React.Fragment>
									)
								})}
							</Table.Body>
						</Table>
					</div>
					<div style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '10px', marginTop: '10px' }}>
						<Button
							className="submit"
							onClick={() => {
								setNewUser(true)
							}}
						>
							Create New Admin
						</Button>
					</div>
				</>
			) : (
				<h3
					style={{
						opacity: '0.5',
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translateX(-50%) translateY(-50%)',
					}}
				>
					No Current Admins
				</h3>
			)}
			{selectedAdminDetails !== null && (
				<Card
					style={{
						top: '50%',
						left: '50%',
						transform: 'translateX(-50%) translateY(-50%)',
						position: 'fixed',
						padding: '30px',
					}}
				>
					<h1>Change Admin Details</h1>
					<Form>
						<Form.Field>
							<Input
								type="text"
								icon="user"
								placeholder="name"
								value={selectedAdminDetails.name}
								onChange={(e) => {
									setSelectedAdminDetails({ ...selectedAdminDetails, name: e.target.value })
								}}
								required
							/>
						</Form.Field>
						<Form.Field>
							<Input
								type="email"
								icon="mail"
								placeholder="email"
								value={selectedAdminDetails.email}
								onChange={(e) => {
									setSelectedAdminDetails({ ...selectedAdminDetails, email: e.target.value })
								}}
								required
							/>
						</Form.Field>
						<Form.Field>
							<Input
								type="password"
								icon="password"
								placeholder="leave empty to keep password"
								value={selectedAdminDetails.newPassword}
								onChange={(e) => {
									setSelectedAdminDetails({ ...selectedAdminDetails, newPassword: e.target.value })
								}}
							/>
						</Form.Field>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<Button
								onClick={() => {
									setSelectedAdminDetails(null)
								}}
							>
								Cancel
							</Button>
							<Button
								type="submit"
								onClick={() => {
									submitEditedAdminDetails(selectedAdminDetails)
									getAllUsers()
								}}
							>
								Submit
							</Button>
						</div>
					</Form>
				</Card>
			)}

			{newUser && (
				<React.Fragment>
					<div className="ui raised card custom-modal">
						<h2 style={{ marginTop: '0px !important' }}>Create New Admin</h2>
						<Form>
							<Form.Field>
								<Input
									type="text"
									placeholder="Email"
									icon="mail"
									required
									onChange={(e) => {
										setEmail(e.target.value)
									}}
									style={{ marginLeft: '5px', marginBottom: '15px' }}
								/>
							</Form.Field>
							<Form.Field>
								<Input
									type="text"
									placeholder="Name"
									icon="user"
									required
									onChange={(e) => {
										setName(e.target.value)
									}}
									style={{ marginLeft: '5px', marginBottom: '15px' }}
								/>
							</Form.Field>
							<Form.Field>
								<Input
									type="text"
									placeholder="Password"
									icon="key"
									required
									onChange={(e) => {
										setPassword(e.target.value)
									}}
									style={{ marginLeft: '5px', marginBottom: '15px' }}
								/>
							</Form.Field>
							<div style={{ display: 'flex' }}>
								<Button
									style={{ margin: 'auto', width: '50%' }}
									onClick={() => {
										setNewUser(false)
									}}
								>
									Cancel
								</Button>
								<Button
									type="submit"
									style={{ width: '50%' }}
									onClick={() => {
										postNewAdmin()
									}}
								>
									Save
								</Button>
							</div>
						</Form>
					</div>
				</React.Fragment>
			)}
		</React.Fragment>
	)
}

const mapStateToProps = (state) => {
	return {
		auth: state.user.auth,
		blitz: state.blitz,
	}
}

export default connect(mapStateToProps)(ManageAccounts)
