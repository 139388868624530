import React, { useState, useEffect } from 'react'
import { Container, List, Icon, Input, Button, Table, Grid, Menu, Segment } from 'semantic-ui-react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { DateTimeInput } from 'semantic-ui-calendar-react'
import BlitzScheduler from '../components/BlitzScheduler'
import ManageUsers from '../components/ManageUsers'
import ManageAdmins from '../components/ManageAdmins'
import ManageComments from '../components/ManageComments'
import BlitzStatistics from '../components/BlitzStatistics'
import { makePostRequest } from '../api'
import { navigate } from '@reach/router'
import { joinRoom } from '../actions/blitz'
import openSocket from 'socket.io-client'
import { SOCKET_BASE } from '../api'
import { updateRoom, updateMsg } from '../actions/blitz'
const moment = require('moment-timezone')

const AdminDashboard = (props) => {
	const { logo, bg, auth, blitz } = props
	let room = blitz.room
	// Global State
	const { token } = props
	// Sub-Menu Selection
	const [navigation, setNavigation] = useState('blitz-scheduler')
	const [socket, connectSocket] = useState(false)
	// Window Responsive Value - In Future Just Use CSS/SASS
	const [dashboardWidth, setDashboardWidth] = useState({
		dashWidth: window.innerWidth < 1024 ? '100%' : '1024px',
		columnOne: window.innerWidth < 1024 ? 16 : 4,
		columnTwo: window.innerWidth < 1024 ? 16 : 12,
	})
	const winWidthFunc = () => {
		window.innerWidth < 1024
			? setDashboardWidth({
					dashWidth: '100%',
					columnOne: 16,
					columnTwo: 16,
			  })
			: setDashboardWidth({
					dashWidth: '1024px',
					columnOne: 4,
					columnTwo: 12,
			  })
	}
	// Authentication Redirect
	useEffect(() => {
		if (!token) {
			navigate('/admin-login')
		}
	}, [token])

	useEffect(() => {
		window.addEventListener('resize', winWidthFunc)
		return () => window.removeEventListener('resize', winWidthFunc)
	}, [])

	useEffect(() => {
		console.log('conn updated')
		if (room.conn) {
			console.log('connecting socket')
			connectSocket(openSocket(SOCKET_BASE, { query: `email=${room.conn.email}` }))
			return function cleanup() {
				socket.disconnect()
			}
		}
	}, [blitz.room.conn])

	useEffect(() => {
		console.log('socket change')
		if (socket !== false) {
			socket.on('update' + room.conn.blitzId, (msg) => {
				console.log(msg)
				updateRoom(msg)
			})
			socket.on('newMsg' + room.conn.blitzId, (msg) => {
				console.log(msg)
				updateMsg(msg)
			})
		}
	}, [socket])

	const joinReq = (body) => {
		makePostRequest('/blitz/join/admin', body, auth.token)
			.then((res) => {
				console.log({ res })
				if (res) {
					joinRoom({ room: res.data.room, conn: body })
				} else {
					console.log('error joining admin')
				}
			})
			.catch((err) => {
				console.log({ err })
				console.error(err)
			})
	}

	const joinActive = async () => {
		if (blitz.active.length > 0) {
			joinReq({ blitzId: blitz.active[0].id, email: auth.user.email, name: auth.user.name })
		} else if (blitz.all.length > 0) {
			// If no active blitz set timer and connect to one
			const start = moment.utc(blitz.all[0].startDate).subtract(30, 'm')
			const now = moment.utc()
			const timerValue = start.diff(now, 'milliseconds')
			if (timerValue < 86400000) {
				setTimeout(() => {
					joinReq({ blitzId: blitz.all[0].id, email: auth.user.email, name: auth.user.name })
				}, timerValue)
			}
		}
	}

	useEffect(() => {
		joinActive()
	}, [])

	return (
		<React.Fragment>
			{token && (
				<div id="admin-dashboard">
					<div id="dashboard-container" className="bg" style={{ backgroundImage: `url(${bg})` }}></div>
					<div id="register-overlay" className="bg"></div>
					<div className="center" style={{ zIndex: '3', display: 'flex', width: dashboardWidth.dashWidth }}>
						<Grid style={{ width: '100%' }}>
							<Grid.Column width={dashboardWidth.columnOne}>
								<Menu pointing vertical>
									<div>
										<img src={logo} style={{ margin: 'auto' }} />
									</div>
									<Menu.Item
										name="Blitz Scheduler"
										className="admin-menu"
										active={navigation === 'blitz-scheduler'}
										onClick={() => {
											setNavigation('blitz-scheduler')
										}}
										icon="calendar"
									/>
									<Menu.Item
										name="Manage Users"
										className="admin-menu"
										active={navigation === 'manage-users'}
										onClick={() => {
											setNavigation('manage-users')
										}}
										icon="users"
										disabled={!blitz.room.conn}
									/>
									<Menu.Item
										name="Manage Comments"
										className="admin-menu"
										active={navigation === 'manage-comments'}
										onClick={() => {
											setNavigation('manage-comments')
										}}
										icon="comments"
										disabled={!blitz.room.conn}
									/>
									<Menu.Item
										name="Manage Admins"
										className="admin-menu"
										active={navigation === 'manage-admins'}
										onClick={() => {
											setNavigation('manage-admins')
										}}
										icon="key"
										disabled={!auth.user.masterAdmin}
									/>
									<Menu.Item
										name="Blitz Statistics"
										className="admin-menu"
										active={navigation === 'blitz-statistics'}
										onClick={() => {
											setNavigation('blitz-statistics')
										}}
										icon="pie graph"
									/>
								</Menu>
							</Grid.Column>
							<Grid.Column stretched width={dashboardWidth.columnTwo}>
								<Segment>
									{navigation === 'blitz-scheduler' && <BlitzScheduler logo={logo} />}

									{navigation === 'manage-users' && <ManageUsers />}

									{navigation === 'manage-comments' && <ManageComments socket={socket} />}

									{navigation === 'manage-admins' && <ManageAdmins />}

									{navigation === 'blitz-statistics' && <BlitzStatistics auth={auth} token={token} />}
								</Segment>
							</Grid.Column>
						</Grid>
					</div>
				</div>
			)}
		</React.Fragment>
	)
}

const mapStateToProps = (state) => {
	return {
		token: _.get(state.user, 'auth.token', null),
		auth: state.user.auth,
		blitz: state.blitz,
	}
}

export default connect(mapStateToProps)(AdminDashboard)
