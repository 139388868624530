import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Container, Input, Button, Transition, Loader, Form, Dropdown } from 'semantic-ui-react'
import { navigate } from '@reach/router'
import { makePostRequest, get } from '../api'
import { joinRoom } from '../actions/blitz'
import _ from 'lodash'
import faker from 'faker'
import phone from 'phone'
import CountdownTimer from 'react-component-countdown-timer'
import 'react-component-countdown-timer/lib/styles.css'
import { isBlitzActive, isBlitzStarted, getCounts } from '../helper'
const moment = require('moment-timezone')

const Login = (props) => {
	const dispatch = useDispatch()
	const { logo, bg } = props
	// Global State Props
	const { activeBlitzId, activeBlitzName, allBlitz, blitz, isLoaded } = props
	// Interaction State
	const [loginActive, setLoginActive] = useState(true)
	const [loggingIn, setLoggingIn] = useState(false)
	const [registerActive, setRegisterActive] = useState(false)
	const [registerError, setRegisterError] = useState(false)
	const [registerSent, setRegisterSent] = useState(false)
	// Input State
	const [email, setEmail] = useState()
	const [name, setName] = useState()
	const [phoneNumber, setPhoneNumber] = useState()
	const [showSuccessHelp, setShowSuccessHelp] = useState(false)
	const [result, setResult] = useState([])
	const [location, setLocation] = useState()
	const [associate, setAssociate] = useState()
	// Countdown
	let counts = false
	let upcomingBlitz = false
	let countdownDisplay = null
	// Location Dropdown
	const stateArr = Object.values(faker.definitions.address.state)
	const provinceArr = [
		'Alberta',
		'British Columbia',
		'Manitoba',
		'New Brunswick',
		'Newfoundland and Labrador',
		'Northwest Territories',
		'Nova Scotia',
		'Nunavut',
		'Ontario',
		'Prince Edward Island',
		'Quebec',
		'Saskatchewan',
		'Yukon',
	]
	const locationArr = [...stateArr, ...provinceArr]
	const locationOptions = _.map(locationArr, (state, index) => ({
		key: index,
		text: state,
		value: state,
	}))

	const flipCards = () => {
		loginActive ? registerActiveFunc() : loginActiveFunc()
		// console.log('flipcards')
	}

	const registerActiveFunc = () => {
		// console.log('registerActiveFunc')
		setLoginActive(false)
		setTimeout(() => {
			setRegisterActive(true)
		}, 500)
	}

	const loginActiveFunc = () => {
		// console.log('loginActiveFunc')
		setRegisterActive(false)
		setTimeout(() => {
			setLoginActive(true)
		}, 500)
	}

	// Auto Populate Details
	useEffect(() => {
		get('/client/checkIP')
			.then((res) => {
				if (res.data.length > 0) {
					//console.log(res.data)
					setLocation(res.data[0].location)
					setName(res.data[0].name)
					setEmail(res.data[0].email)
					setPhoneNumber(res.data[0].phone)
					setAssociate(res.data[0].associate)
					validatePhoneNumber(res.data[0].phone)
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])

	if (blitz.active.length > 0) {
		upcomingBlitz = blitz.active[0]
	} else if (blitz.all.length > 0) {
		upcomingBlitz = blitz.all[0]
	}

	if (upcomingBlitz) {
		counts = getCounts(upcomingBlitz.startDate, upcomingBlitz.endDate)
		if (counts.start > 0) {
			countdownDisplay = (
				<div>
					<h1 style={{ textTransform: 'uppercase', fontWeight: 'bolder' }}>
						{upcomingBlitz.name}{' '}
						{isBlitzActive(upcomingBlitz) ? 'Is open to join, Blitz begins in...' : 'Will Begin In'}
					</h1>
					<CountdownTimer count={counts.start} border backgroundColor="#333" color="#fff" />
				</div>
			)
		} else {
			countdownDisplay = (
				<div>
					<h1 style={{ textTransform: 'uppercase', fontWeight: 'bolder' }}>
						{upcomingBlitz.name} Is Active! Blitz ending in...
					</h1>
					<CountdownTimer count={counts.end} border backgroundColor="#333" color="#fff" />
				</div>
			)
		}
	}
	useEffect(() => {
		console.log('blitz check')
		// Disable Login if Blitz not Active
		if (!upcomingBlitz && isLoaded) {
			// No Upcoming Blitz at All
			console.log('No Upcoming Blitz at All')
			registerActiveFunc()
		} else if (!isBlitzActive(upcomingBlitz) && isLoaded) {
			// Upcoming Blitz is not Active
			console.log('Upcoming Blitz is not Active')
			registerActiveFunc()
			// Set Timer to Enable If Countdown Drops
			const start = moment.utc(upcomingBlitz.startDate).subtract(30, 'm')
			const now = moment.utc()
			const timerValue = start.diff(now, 'milliseconds')
			console.log('timer until blitz', timerValue)
			if (timerValue < 86400000) {
				setTimeout(() => {
					console.log('trigger loginActive')
					loginActiveFunc()
				}, timerValue)
			}
		} else if (isBlitzActive(upcomingBlitz) && isLoaded) {
			// Active Blitz
			console.log('Active Blitz')
			loginActiveFunc()
		} else {
			console.log({ upcomingBlitz })
			console.log('isBlitzActive', isBlitzActive(upcomingBlitz))
			console.log('isLoaded', isLoaded)
			console.log('Exception Caught')
		}
	}, [blitz, isLoaded])

	const validatePhoneNumber = (phoneInput) => {
		setPhoneNumber(phoneInput)
		const result = phone(phoneInput, '', false)
		console.log({ result })
		result.length ? setShowSuccessHelp(true) : setShowSuccessHelp(false)
		setResult(result ? result[0] : '')
	}

	const login = () => {
		if (!showSuccessHelp) {
			dispatch({ type: '@error/SET_MESSAGE', payload: 'Invalid Phone Number' })
			setTimeout(function() {
				dispatch({ type: '@error/SET_MESSAGE', payload: '' })
			}, 4000)
		} else if (
			email &&
			email.includes('@') &&
			name &&
			name.length > 0 &&
			location &&
			location.length > 0 &&
			associate &&
			associate.length > 0
		) {
			const conn = { email, name, location, blitzId: activeBlitzId, phone: phoneNumber, associate }
			makePostRequest('/blitz/join/', conn)
				.then((res) => {
					if (res) {
						setLoggingIn(false)
						joinRoom({ room: res.data.room, conn })
						navigate('/user-dashboard')
					}
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}

	const register = () => {
		setRegisterError(false)
		setLoggingIn(true)
		const postObj = {
			email,
			name,
		}
		makePostRequest('/list/subscribe', postObj)
			.then((res) => {
				setRegisterSent(true)
			})
			.catch((err) => {
				setLoggingIn(false)
				setRegisterError(err)
			})
	}

	// REGISTER CARD COMPONENT
	// <Form>
	// 	<Form.Field>
	// 		<Input
	// 			disabled
	// 			type="text"
	// 			placeholder="Email"
	// 			onChange={(e) => {
	// 				setEmail(e.target.value)
	// 			}}
	// 		/>
	// 	</Form.Field>
	// 	<Form.Field>
	// 		<Input
	// 			disabled
	// 			type="text"
	// 			placeholder="Name"
	// 			onChange={(e) => {
	// 				setName(e.target.value)
	// 			}}
	// 		/>
	// 	</Form.Field>
	// 	{registerError && <label className="field-error">{registerError}</label>}
	// 	<Button
	// 		type="submit"
	// 		//disabled={!email && !name}
	// 		disabled={true}
	// 		onClick={() => {
	// 			register()
	// 		}}
	// 	>
	// 		(COMING SOON - NOT ENABLED) Register to receive Blitz updates!
	// 		{loggingIn && <Loader />}
	// 	</Button>
	// </Form>

	return (
		<div id="login-wrap">
			<Container id="login-container">
				<Transition visible={loginActive} animation={'horizontal flip'} duration={500}>
					<div className="ui raised card">
						<img src={logo} />
						<Form style={{marginBottom: "50px"}}>
							<Form.Field>
								<Input
									type="text"
									placeholder="Name"
									onChange={(e) => {
										setName(e.target.value)
									}}
									onKeyPress={(e) => {
										if (e.which === 13) {
											login()
										}
									}}
									required
									value={name}
								/>
							</Form.Field>
							<Form.Field>
								<Input
									type="email"
									placeholder="Email"
									onChange={(e) => {
										setEmail(e.target.value)
									}}
									required
									value={email}
								/>
							</Form.Field>
							<Form.Field>
								<Dropdown
									placeholder="State or Province"
									search
									selection
									options={locationOptions}
									required
									onChange={(e, data) => {
										setLocation(data.value)
									}}
									value={location}
								/>
							</Form.Field>
							<Form.Field>
								<Input
									type="tel"
									placeholder="Mobile Phone Number"
									onChange={(e) => {
										validatePhoneNumber(e.target.value)
									}}
									icon={showSuccessHelp ? 'check circle' : 'exclamation circle'}
									required
									value={phoneNumber}
								/>
							</Form.Field>
							<Form.Field>
								<Input
									type="text"
									placeholder="Associate Number"
									onChange={(e) => {
										setAssociate(e.target.value.replace(/[^0-9]/g, ''))
									}}
									patten="[0-9]*"
									required
									value={associate}
								/>
							</Form.Field>
							{registerActive ? (
								<Button
									disabled={!email && !name && !upcomingBlitz && isLoaded}
									type="submit"
									onClick={() => {
										login()
									}}
								>
									Join {activeBlitzName}!{loggingIn && <Loader />}
								</Button>
							) : (
								<Button
									disabled={!email && !name}
									type="submit"
									onClick={() => {
										login()
									}}
								>
									Join the Blitz!
									{loggingIn && <Loader />}
								</Button>
							)}
						</Form>
						<label
							onClick={() => {
								navigate('/admin-login')
							}}
						>
							Admin Login
						</label>
					</div>
				</Transition>
				<Transition visible={registerActive} animation={'horizontal flip'} duration={500}>
					<div className="ui raised card">
						<img src={logo} />
						{registerSent ? (
							<h2>You have been signed up to receive Blitz emails!</h2>
						) : (
							<React.Fragment>
								<h2>
									To register to receive reminders for all Blitz sessions, simply text the word "Blitz" to &#40;580&#41; 324-0717.
								</h2>
								<label
									style={{
										cursor: 'pointer',
										position: 'absolute',
										opacity: '0.5',
										left: '50%',
										bottom: '30px',
										transform: 'translateX(-50%)',
									}}
									onClick={() => {
										navigate('/admin-login')
									}}
								>
									Admin Login
								</label>
							</React.Fragment>
						)}
					</div>
				</Transition>
			</Container>
			<Container id="register-container">
				<div id="register-text">
					{countdownDisplay}
					<h2
						style={{
							width: '100%',
							textAlign: 'left',
							margin: '0 auto',
							color: '#fff',
							backgroundColor: '#333',
							padding: '30px 10%',
							marginBottom: '30px',
							fontWeight: '400',
							maxHeight: '750px',
							overflow: 'hidden auto',
						}}
					>
						As an associate with LegalShield Business Solutions, one of the most important keys to success is
						consistently prospecting new companies. You are invited to participate in a prospecting blitz each week,
						along with other associates across North America!
						<br />
						<br />
						During this Blitz, you are encouraged to prospect new companies, and track the number of calls you make, as
						well as the number of appointments or saves you set. We will be giving rewards away to some participants, but of
						course the real rewards are the results will come to each associate through this kind of concentrated and
						consistent effort! This Blitz is intended to supplement other regular prospecting efforts of each of our
						associates.
						<br />
						<br />
						You can login to the Blitz 30 minutes before its start, Happy Prospecting!
					</h2>
					{registerActive ? (
						activeBlitzId && (
							<Button
								className="animated-button"
								style={{
									zIndex: '2',
									color: '#fff',
									backgroundColor: '#333',
									height: '45px',
									fontSize: '1.2em',
								}}
								onClick={() => {
									flipCards()
								}}
							>
								Join Active Blitz
								<div className="layer">LET'S DO IT</div>
							</Button>
						)
					) : (
						<Button
							style={{
								zIndex: '2',
								color: '#fff',
								backgroundColor: '#333',
								height: '45px',
								fontSize: '1.2em',
								display: !loginActive && blitz.active.length === 0 ? 'none' : 'block',
								margin: 'auto',
							}}
							onClick={() => {
								if (window.innerWidth <= 1024) {
									document.querySelector('body').scrollTo(0, 0)
									setTimeout(() => {
										flipCards()
									}, 800)
								} else {
									flipCards()
								}
							}}
						>
							Register for future Blitzes
						</Button>
					)}
				</div>
				<div id="register-overlay" className="bg"></div>
				<div id="register-bg" className="bg" style={{ backgroundImage: `url(${bg})` }}></div>
			</Container>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		allBlitz: _.get(state, 'blitz.all', []),
		activeBlitz: _.get(state, 'blitz.active', []),
		activeBlitzId: _.get(state, 'blitz.active.0.id', undefined),
		activeBlitzName: _.get(state, 'blitz.active.0.name', ''),
		blitz: state.blitz,
		isLoaded: _.get(state, 'blitz.isLoaded', null),
	}
}

export default connect(mapStateToProps)(Login)
