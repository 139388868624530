import React, { useState, useEffect } from 'react'
import { Container, List, Icon, Input, Button, Table, Form, Dropdown } from 'semantic-ui-react'
import _ from 'lodash'
import { DateTimeInput, DateInput, TimeInput } from 'semantic-ui-calendar-react'
import { connect } from 'react-redux'
import { fetchActive, fetchAll, joinRoom, updateAllBlitz, deleteAllBlitz } from '../actions/blitz'
import { makePostRequest, put } from '../api'

const moment = require('moment-timezone')

const BlitzScheduler = (props) => {
	const { logo } = props
	// Global State
	const { blitz, auth, token } = props
	console.log({ blitz })
	let userId = auth.user.id
	// Table Sorting
	const [column, setColumn] = useState(null)
	const [direction, setDirection] = useState(null)
	const [sessionData, setSessionData] = useState([])
	// Blitz Calendar Picker
	const [blitzName, setBlitzName] = useState()
	const [date, setDate] = useState()
	const [endTime, setEndTime] = useState("")
	const [schedulingSession, setSchedulingSession] = useState(false)
	const [selectedSession, setSelectedSession] = useState(false)
	const [startTime, setStartTime] = useState("")
	const [type, setType] = useState("")

	const scheduleInputStyle = {
		marginBottom: '15px',
		width: '220px',
	}

	// Remove all autocompletes
	useEffect(() => {
		Array.from(document.querySelectorAll('input')).forEach((input, index) => {
			input.setAttribute('autocomplete', 'off')
		})
	}, [schedulingSession])

	// Map Global to Local For Sorting
	useEffect(() => {
		setSessionData(blitz.all)
	}, [blitz])

	// Table Sorting
	const handleSort = (clickedColumn) => {
		const newDirection = direction === 'ascending' ? 'descending' : 'ascending'
		console.log({ sessionData })
		if (column !== clickedColumn) {
			setColumn(clickedColumn)
			setSessionData(_.sortBy(sessionData, [clickedColumn]))
			setDirection('ascending')
		} else {
			setSessionData(sessionData.reverse())
			setDirection(newDirection)
		}
	}

	// Handling Blitz Scheduler
	const setBlitz = (type, value) => {
		type(value)
	}
	const formatTime = (date, time) => {
		let m = moment.tz(`${date}:${time}`, 'DD-MM-YYYY:HH:mm', moment.tz.guess())
		return m.utc().format('YYYY-MM-DD:HH:mm')
	}
	const postNewBlitz = () => {
		put(
			'/blitz',
			{
				userId: userId,
				startDate: formatTime(date, startTime),
				endDate: formatTime(date, endTime),
				room: {
					name: blitzName,
				},
				type: type
			},
			token,
		)
			.then((res) => {
				console.log(res)
				updateAllBlitz(res.data)
				setSchedulingSession(false)
				setStartTime('')
				setEndTime('')
				setDate('')
			})
			.catch((err) => {
				console.log(`Error Saving Blitz ${err.response}`)
			})
	}
	const deleteBlitz = (id) => {
		makePostRequest(`/blitz/delete/${id}`, {}, token)
			.then((res) => {
				console.log(res)
				deleteAllBlitz(id)
			})
			.catch((err) => {
				console.log(`Error Deleting Blitz ${err.response}`)
			})
	}

	return (
		<React.Fragment>
			<h1 style={{ marginBottom: '15px', textAlign: 'left' }}>Upcoming Scheduled Blitz Sessions</h1>
			<Table sortable selectable striped>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell
							sorted={column === 'name' ? direction : null}
							onClick={() => {
								handleSort('name')
							}}
						>
							Name
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={column === 'startDate' ? direction : null}
							onClick={() => {
								handleSort('startDate')
							}}
						>
							Start
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={column === 'endDate' ? direction : null}
							onClick={() => {
								handleSort('endDate')
							}}
						>
							End
						</Table.HeaderCell>
						<Table.HeaderCell
							sorted={column === 'type' ? direction : null}
							onClick={() => {
								handleSort('type')
							}}
						>
							Type
						</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{_.map(sessionData, ({ startDate, endDate, name, id, blitzType = '' }, index) => (
						<Table.Row
							key={id}
							positive={moment().diff(startDate, 'minutes') <= 30 && moment(endDate).isBefore(moment())}
							className={selectedSession === id ? 'selected-row' : ''}
							onClick={() => {
								setSelectedSession(id)
							}}
						>
							<Table.Cell>{name}</Table.Cell>
							<Table.Cell>{moment(startDate).format('LLLL')}</Table.Cell>
							<Table.Cell>{moment(endDate).format('LLLL')}</Table.Cell>
							<Table.Cell>{blitzType}</Table.Cell>
						</Table.Row>
					))}
				</Table.Body>
			</Table>
			<div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '10px' }}>
				<Button
					disabled={!selectedSession}
					onClick={() => {
						deleteBlitz(selectedSession)
					}}
				>
					Delete Blitz
				</Button>
				<Button
					className="submit"
					onClick={() => {
						setSchedulingSession(true)
					}}
				>
					Schedule New Blitz
				</Button>
			</div>
			{schedulingSession && (
				<React.Fragment>
					<div className="ui raised card custom-modal">
						<h2 style={{ marginTop: '0px !important' }}>Schedule Blitz Time</h2>
						<Form>
							<Form.Field>
								<Input
									type="text"
									placeholder="Blitz Name"
									required
									onChange={(e) => {
										setBlitzName(e.target.value)
									}}
									style={{ ...scheduleInputStyle }}
									icon="pencil"
									iconPosition="right"
									autoComplete="off"
								/>
							</Form.Field>
							<Form.Field>
								<DateInput
									name="date"
									placeholder="Start Date"
									value={date}
									iconPosition="right"
									onChange={(event, { name, value }) => {
										console.log('start date')
										console.log(value)
										setBlitz(setDate, value)
									}}
									style={scheduleInputStyle}
									closable={true}
									closeOnMouseLeave={false}
									popupPosition="center center"
									autoComplete="off"
									required
								/>
							</Form.Field>
							<Form.Field>
								<TimeInput
									name="startTime"
									placeholder="Start Time"
									value={startTime}
									iconPosition="right"
									onChange={(event, { name, value }) => {
										console.log('start time')
										console.log(value)
										setBlitz(setStartTime, value)
									}}
									style={scheduleInputStyle}
									closable={true}
									closeOnMouseLeave={false}
									popupPosition="center center"
									autoComplete="off"
									required
								/>
							</Form.Field>
							<Form.Field>
								<TimeInput
									name="endTime"
									placeholder="End Time"
									value={endTime}
									iconPosition="right"
									onChange={(event, { name, value }) => {
										console.log('end time')
										console.log(value)
										setBlitz(setEndTime, value)
									}}
									style={scheduleInputStyle}
									closable={true}
									closeOnMouseLeave={false}
									popupPosition="center center"
									autoComplete="off"
									required
								/>
							</Form.Field>
							<Form.Field>
								<Dropdown 
									placeholder="Select Type" 
									fluid 
									selection 
									options={[
										{ key: '0', text: 'Appointments', value: 'Appointments' }, 
										{ key: '1', text: 'Saves', value: 'Saves' }
									]} 
									onChange={(e, { value }) => { setType(value) }}
								/>
							</Form.Field>
							<div style={{ display: 'flex' }}>
								<Button
									style={{ margin: 'auto', width: '50%' }}
									onClick={() => {
										setSchedulingSession(false)
									}}
								>
									Cancel
								</Button>
								<Button
									type="submit"
									style={{ width: '50%' }}
									onClick={() => {
										postNewBlitz()
									}}
								>
									Save
								</Button>
							</div>
						</Form>
					</div>
				</React.Fragment>
			)}
		</React.Fragment>
	)
}

function mapStateToProps(state) {
	return {
		blitz: state.blitz,
		auth: state.user.auth,
		token: state.user.auth.token,
	}
}

const mapPropsToDispatch = (dispatch) => {
	return {
		fetchActive: () => fetchActive(),
		fetchAll: () => fetchAll(),
	}
}

export default connect(mapStateToProps, mapPropsToDispatch)(BlitzScheduler)
